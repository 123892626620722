<template>
  <div class="ant-upload-preview">
    <div class="avatatImg">
      <a-upload
        name="avatar"
        listType="picture-card"
        :showUploadList="false"
        :beforeUpload="beforeUpload"
        :customRequest="function() {}"
        @change="handleChange"
        accept="image/png,image/jpeg"
      >
        <img class="upload_img" v-if="imageUrl" :src="imageUrl" alt="avatar" />
        <div v-else>
          <a-icon :type="loading ? 'loading' : 'plus'" />
          <div class="ant-upload-text">上传图片</div>
        </div>
      </a-upload>
      <div class="text">
        <div>{{ avatarTextTop }}</div>
        <div>{{ avatarTextBottom }}</div>
      </div>
    </div>
    <!-- modal -->
    <CropperModal
      ref="CropperModal"
      :imgType="imgType"
      :fileName="fileName"
      :cropperMode="cropperMode"
      :type="type"
      :autographType="autographType"
      @cropper-no="handleCropperClose"
      @cropper-ok="handleCropperSuccess"
    ></CropperModal>
  </div>
</template>

<script>
import { message } from "ant-design-vue";
import CropperModal from "./CropperModal";
export default {
  components: { CropperModal },
  props: {
    //图片裁切配置
    options: {
      type: Object,
      default: function() {
        return {
          autoCrop: true, //是否默认生成截图框
          autoCropWidth: 1029, //默认生成截图框宽度
          autoCropHeight: 480, //默认生成截图框高度
          fixedBox: true, //是否固定截图框大小 不允许改变
          previewsCircle: false, //预览图是否是原圆形
          outputType: "png",
          title: "修改图片",
        };
      },
    },
    avatarTextTop: {
      type: String,
      default: "推荐使用160*160px,JPG.PNG.JPEG格式",
    },
    avatarTextBottom: {
      type: String,
      default: "图片小于1M",
    },
    //  1-> 后台上传  2-保利威上传
    type: {
      type: Number,
      default: 1,
    },
    // 签名类型
    autographType: {
      type: String,
      default: "splashImage",
    },
    // 上传图片的大小，单位M
    imgSize: {
      type: Number,
      default: 2,
    },
    //图片存储在oss上的上级目录名
    imgType: {
      type: String,
      default: "",
    },
    // 图片地址
    imageUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      fileName: "", // 文件名
      loading: false,
      isStopRun: false,
      cropperMode: "contain", // 图片渲染方式
    };
  },
  methods: {
    //从本地选择文件
    handleChange(info) {
      if (!info.file.status) {
        this.loading = false;
      } else {
        this.loading = true;
      }
      this.fileName = info.file.name;
      if (this.isStopRun) {
        return;
      }
      const { options } = this;
      this.getBase64(info.file.originFileObj, (imageUrl) => {
        const target = Object.assign({}, options, {
          img: imageUrl,
        });
        this.$refs.CropperModal.edit(target);
      });
    },
    // 上传之前 格式与大小校验
    beforeUpload(file) {
      this.$emit("avatarLoadingFn", true);
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("不能上传其他类型的图片");
      }

      // 获取上传图片尺寸
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        var img = new Image();
        img.src = reader.result;
        img.onload = () => {
          // 1、先根据图片裁剪的尺寸
          if (this.options.autoCropWidth > this.options.autoCropHeight) {
            // 2、再根据上传图片尺寸
            this.cropperMode = this.options.autoCropWidth + "px auto";
          } else {
            if (img.width >= img.height) {
              this.cropperMode = "auto " + this.options.autoCropHeight + "px";
            } else {
              this.cropperMode = this.options.autoCropWidth + "px auto";
            }
          }
        };
      };

      return isJpgOrPng;
    },
    //获取服务器返回的地址
    handleCropperSuccess(data) {
      //将返回的数据回显
      this.loading = false;
      this.$emit("avatarfn", data);
    },
    // 取消上传
    handleCropperClose() {
      this.loading = false;
    },
    getBase64(img, callback) {
      if (img) {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.avatar-upload-wrapper {
  height: 180px;
  width: 100%;
}

.ant-upload-preview {
  background-color: #fff;
  .avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
  }
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }
  .upload_img {
    width: 100%;
  }
  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }
}
.ant-upload-picture-card-wrapper {
  width: auto;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.avatatImg {
  // width: 100%;
  display: flex;
  align-items: center;
  .text {
    margin-left: 20px;
    div {
      line-height: 30px !important;
      font-weight: 600;
    }
  }
}
</style>
