<template>
  <a-modal
    :visible="visible"
    :title="options.title"
    :maskClosable="false"
    :confirmLoading="confirmLoading"
    :width="1300"
    @cancel="cancelHandel"
  >
    <a-row>
      <a-col :xs="24" :md="24" :style="{ height: '600px' }">
        <vue-cropper
          ref="cropper"
          :img="options.img"
          :info="true"
          :autoCrop="options.autoCrop"
          :autoCropWidth="options.autoCropWidth"
          :autoCropHeight="options.autoCropHeight"
          :outputType="options.outputType"
          :fixedBox="options.fixedBox"
          :mode="cropperMode"
          @realTime="realTime"
        >
        </vue-cropper>
      </a-col>
      <!-- <a-col :xs="24" :md="12" :style="{ height: '250px' }">
        <div
          :class="
            options.previewsCircle
              ? 'avatar-upload-preview'
              : 'avatar-upload-preview_range'
          "
        >
          <img :src="previews.url" :style="previews.img" />
        </div>
      </a-col> -->
    </a-row>
    <template slot="footer">
      <a-button key="back" @click="cancelHandel">取消</a-button>
      <a-button
        key="submit"
        type="primary"
        :loading="confirmLoading"
        @click="okHandel"
        >保存</a-button
      >
    </template>
  </a-modal>
</template>

<script>
import axios from "axios";
import { domainName } from "@/config/index";
// import { LiveGetSecretApi } from "@/request/api/coursManage";
export default {
  props: {
    //图片存储在oss上的上级目录名
    imgType: {
      type: String,
      default: "",
    },
    fileName: {
      type: String, // 文件名
      default: "",
    },
    cropperMode: {
      // 图片渲染方式
      type: String,
      default: "contain",
    },
    //  1-> 后台上传  2-保利威上传
    type: {
      type: Number,
      default: 1,
    },
    // 签名类型
    autographType: {
      type: String,
      default: "splashImage",
    },
  },
  data() {
    return {
      visible: false,
      img: null,
      confirmLoading: false,
      options: {
        img: "", //裁剪图片的地址
        autoCrop: true, //是否默认生成截图框
        autoCropWidth: 180, //默认生成截图框宽度
        autoCropHeight: 180, //默认生成截图框高度
        fixedBox: true, //是否固定截图框大小 不允许改变
        previewsCircle: false, //预览图是否是原圆形
        outputType: "png",
        title: "修改图片",
      },
      previews: {},
      url: {
        upload: "/sys/common/saveToImgByStr",
      },
    };
  },
  methods: {
    edit(record) {
      const { options } = this;
      this.visible = true;
      this.options = Object.assign({}, options, record);
    },
    // 取消截图
    cancelHandel() {
      this.confirmLoading = false;
      this.visible = false;
      this.$emit("cropper-no");
    },
    // 确认截图
    okHandel() {
      const that = this;
      that.confirmLoading = true;
      // 后台上传
      if (this.type == 1) {
        // 获取截图的base64 数据
        this.$refs.cropper.getCropData((data) => {
          let file = this.dataURLtoFile(data, this.fileName);
          let formData = new window.FormData();
          formData.append("uploadFile", file);
          // axios
          //   .post(`${domainName}/frame-web/files/admin/upload`, formData)
          //   .then((res) => {
          //     if (res.data.code == 200) {
          //       this.visible = false;
          //       this.confirmLoading = false;
          //       this.$emit("cropper-ok", res.data.data);
          //     }
          //   });
          axios({
            url: `${domainName}/frame-web/files/admin/upload`,
            method:"post",
            data: formData,
            headers: { accessToken: localStorage.getItem("token") },
          }).then((res) => {
            if (res.data.code == 200) {
              this.visible = false;
              this.confirmLoading = false;
              this.$emit("cropper-ok", res.data.data);
            }
          });
        });
        // 保利威上传
      } else if (this.type == 2) {
        LiveGetSecretApi({ type: this.autographType }).then(
          ({ code, data }) => {
            if (code == 200) {
              this.$refs.cropper.getCropData((dataUrl) => {
                let file = this.dataURLtoFile(dataUrl, this.fileName);
                let formData = new window.FormData();
                formData.append("file", file);
                formData.append("appId", data.appId);
                formData.append("timestamp", data.timestamp);
                formData.append("sign", data.sign);
                formData.append("type", this.autographType);
                axios
                  .post(
                    `http://api.polyv.net/live/v3/common/upload-image`,
                    formData
                  )
                  .then((res) => {
                    if (res.data.code === 200) {
                      this.visible = false;
                      this.confirmLoading = false;
                      that.$emit("cropper-ok", {
                        url: res.data.data[0],
                      });
                    }
                  });
              });
            }
          }
        );
      }
    },
    //移动框的事件
    realTime(data) {
      this.previews = data;
    },
    // base 64 转成二进制文件流
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
  },
};
</script>

<style lang="less" scoped>
.avatar-upload-preview_range,
.avatar-upload-preview {
  position: absolute;
  top: 50%;
  transform: translate(50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: 0 0 4px #ccc;
  overflow: hidden;
  img {
    background-color: red;
    height: 100%;
  }
}
.avatar-upload-preview_range {
  border-radius: 0;
}
</style>
